import React from 'react'

import Lottie from "lottie-react";
import codinganimation from "../../codinganimation.json"

const Languages = () => {
  return (
    
     <Lottie animationData={codinganimation} loop={true} />

  )
}

export default Languages